import type { Category } from '@/modules/nuxt-api/models/Category'

export const sizes = {
  categories: {
    mobile: { w: '500', h: '500' },
    desktop: { w: '500', h: '500' }
  },
  categoriesFix: {
    mobile: { w: '64', h: '64' },
    desktop: { w: '64', h: '64' }
  }
}

type CategoryImage = {
  url: string
  mobileUrl: string | undefined
  alt: string
}

type DictionaryCategory = {
  icon?: string
  path?: { path: string }
  parentCode?: string
  megamenuSort?: number
  onlyCatalog?: boolean
  image?: CategoryImage
  isAccent?: boolean
}

export type DictionaryCategories = Record<string, DictionaryCategory>

export type DictionaryCategoryExtended =
  DictionaryCategory & (Category | { code: string, title: string }) & { submenu?: DictionaryCategoryExtended[] }
